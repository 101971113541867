import DataMetrics from 'components/DataMetrics'
import DrivingForDollars from 'components/driving-for-dollars'
import EasyOverview from 'components/EasyOverview'
import MetaInfo from 'components/MetaInfo'
import SearchSection from 'components/SearchSection'
import SectionTitle from 'components/SectionTitle'
import TrialButton from 'components/cta-button'
import { GetStaticProps } from 'next'
import Image from 'next/image'
import React, { FC } from 'react'
import YouTube from 'react-youtube'
import { City, State } from 'store'
import css from 'styles/home.module.scss'
import { query } from 'utils/db'
import homeBk from 'public/home-background.png'
import woman from 'public/woman-laptop.png'

export const getStaticProps: GetStaticProps = async (context) => {
  const stateQuery = await query('select name, path from web.view_states order by name')
  const cityQuery = await query('select distinct name, path from web.view_cities where seq <= 200 order by name')

  return {
    props: {
      cities: cityQuery.rows,
      states: stateQuery.rows
    }
  }
}

const ldSchema = {
  '@context': 'https://schema.org',
  '@type': 'Organization',
  name: 'PropertyReach',
  url: 'https://www.propertyreach.com/',
  logo: 'https://www.propertyreach.com/logo-sm.png',
  address: {
    '@type': 'PostalAddress',
    streetAddress: '548 Market St Suite 29296',
    addressLocality: 'San Francisco',
    addressRegion: 'CA',
    postalCode: '94104',
    addressCountry: 'US'
  },
  'contactPoint': {
    '@type': 'ContactPoint',
    contactType: 'contact',
    telephone: '469-902-9879',
    email: ''
  }
}

const ldSchemaVideo = {
  "@context": "http://schema.org",
  "@type": "VideoObject",
  "name": "Finding Hidden Investment Opportunities with PropertyReach",
  "description": "PropertyReach.com gives you access to hidden opportunities. With instant skip tracing, you can contact homeowners and find discounted properties before they hit the market. With millions of property records and integrated marketing tools, PropertyReach.com is your all-in-one investment solution.  Start your free trial today at PropertyReach.com",
  "thumbnailUrl": "https://i.ytimg.com/vi/yxxvOXYkUMo/default.jpg",
  "uploadDate": "2024-10-31T16:58:28Z",
  "duration": "PT31S",
  "embedUrl": "https://www.youtube.com/embed/yxxvOXYkUMo",
  "interactionCount": "1"
}

const features = [
  {
    name: 'Nationwide Data Access',
    description: 'Access to over 156 million properties, encompassing residential, commercial, vacant land, and more, providing detailed insights on property characteristics, ownership details, mortgages, equity, and so much more.',
  }, {
    name: 'Property & Owner Insights',
    description: 'Detailed, up-to-date, and transparent property, ownership, financial, and situational information empowers you to make well-informed decisions, spot prime opportunities, and stay vigilant regarding potential risks.',
  }, {
    name: 'List Building',
    description: 'Create unlimited lists utilizing over 150 property, ownership, financial, and situational filters to market to the right leads at the right time. pinpoint leads that align with your specific criteria and objectives.',
  }, {
    name: 'Skip Tracing',
    description: 'Connect with more than 250 million US individuals, including over 1 billion phone numbers and email addresses. Our multi-sourced, updated daily, pre-linked contact data ensures accurate connections instantly, eliminating any need for waiting.',
  }, {
    name: 'Build CMA/Comp Reports',
    description: 'Effortlessly generate CMA/Comp reports using the latest public record sales and MLS data, helping determine a property’s current and potential value, including its After Repair Value (ARV).',
  }, {
    name: 'Share With Your Team',
    description: 'Add team members with ease and share access, fostering business growth through effective task delegation. Ensure optimal productivity by distributing responsibilities among team members.',
  }
]

const HomePage: FC<{
  cities?: City[]
  states?: State[]
}> = ({
  cities = [],
  states = []
}) => (
  <div className={css.search}>
    <MetaInfo
      description="Discover real estate investment opportunities today with PropertyReach. Instant access to data, tools and solutions. Land deals and invest in prime properties."
      ldSchema={ldSchema}
      ldSchema2={ldSchemaVideo}
    />
    <div className={css.banner}>
      <Image
        src={homeBk}
        className={css.background}
        alt="PropertyReach search for instant access to make informed real estate investment decisions."
        priority
        unoptimized
      />
      <div className={css.inner}>
        <Image
          src={woman}
          className={css.woman}
          alt="Ready for something new?"
          unoptimized
        />
        <div className={css.innerContent}>
          <div className={css.title1}>The Ultimate Hub for</div>
          <div className={css.title2}>Reliable Property Data</div>
          <div className={css.description}>
            Explore a one-stop shop for nationwide real estate
            insights. Access comprehensive property, owner,
            financial, situational, and MLS data to drive informed
            decisions, streamline your workflow, and grow your
            business.
          </div>
          <TrialButton type="large" />
        </div>
      </div>
    </div>
    <div className={css.content}>
      <SectionTitle centered primary>
        Real Estate Property Data <span>You Can Trust</span>
      </SectionTitle>
      <div className={css.features}>
        {features.map(({ name, description }) => (
          <div className={css.feature} key={name}>
            <div className={css.name}>{name}</div>
            <div className={css.description}>{description}</div>
          </div>
        ))}
      </div>
    </div>
    <DataMetrics />
    <EasyOverview />
    {/*<PricePoints />*/}
    <DrivingForDollars />
    <div className={css.video}>
      <SectionTitle>
        See How You can Find <span>Hidden Investment Opportunities</span> with PropertyReach
      </SectionTitle>
      <YouTube videoId="yxxvOXYkUMo" />
    </div>
    <SearchSection
      header={['Search By', '*State']}
      items={states}
    />
    <SearchSection
      description="View streets and property metrics by city."
      header={['Popular', '*City']}
      items={cities}
      splitByLetter
    />
  </div>
)

export default HomePage
